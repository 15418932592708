<template>
  <li :class="{ on: selected }">
    <!-- <router-link :to='link' class="link_gnb">{{ title }}</router-link> -->
    <router-link ref="linkGnb" :to="link" class="link_gnb">
      {{ title }}
    </router-link>
    <!-- <router-link v-if="isApproveMenu" :to='link' class="link_gnb" ref="linkGnb" >{{ title }}</router-link>
     <router-link v-else to='' class="link_gnb" @click.native="activeMenu" ref="linkGnb" >{{ title }}</router-link> -->
    <div v-if="hasSub" class="lnb_purchase">
      <ul ref="listLnb" class="list_lnb" :style="'padding:0 ' + lnbPadding + 'px'">
        <li
          v-for="item in arrSub"
          :key="item.menuId"
          :class="{ on: selectedSubId === item.menuId }"
        >
          <!-- <router-link :to='item.link' class="link_lnb">{{ item.title }}</router-link> -->
          <router-link :to="item.link" class="link_lnb">
            {{ item.title }}
          </router-link>
          <!-- <router-link v-if="item.isApproveMenu" :to='item.link' class="link_lnb">{{ item.title }}</router-link>
            <router-link v-else to='' class="link_lnb" @click.native="activeSubMenu(item)" >{{ item.title }}</router-link> -->
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    title: String,
    link: String,
    arrSub: Array,
    selected: Boolean,
    selectedSubId: String,
    isApproveMenu: Boolean,
    index: Number,
  },
  data() {
    return {
      lnbPadding: 18,
    };
  },
  computed: {
    hasSub() {
      return this.arrSub && this.arrSub.length > 0;
    },
  },
  mounted() {
    this.setLnbPadding();
  },
  methods: {
    setLnbPadding() {
      this.$nextTick(() => {
        if (this.$refs.listLnb) {
          const linkGnbWidth = this.$refs.linkGnb.$el.offsetWidth;
          const firstLnb = this.$refs.listLnb.childNodes[0];
          const lastLnbIndex = this.$refs.listLnb.childNodes.length - 1;
          const lastLnb = this.$refs.listLnb.childNodes[lastLnbIndex];
          const lnbItem = this.index < 4 ? firstLnb : lastLnb;
          let trimWordLength = 0;
          lnbItem.innerText.split("").forEach((word) => {
            if (word == " ") {
              trimWordLength += 1;
            }
          });
          const lnbTextLength = lnbItem.innerText.length - trimWordLength;
          const lnbWidth = lnbTextLength * 12 + trimWordLength * 4;
          this.lnbPadding = (linkGnbWidth - lnbWidth) / 2;
        }
      });
    },
    activeMenu() {
      const host = window.location.hostname;
      const isLocal = host === "localhost";

      if (!this.isApproveMenu) {
        if (isLocal) {
          window.location.href = process.env.VUE_APP_ADMIN_WEB_ENDPOINT + `${this.link}`;
        } else {
          window.location.href = `${this.link}`;
        }
      }
    },
    activeSubMenu(item) {
      const host = window.location.hostname;
      const isLocal = host === "localhost";

      if (!item.isApproveMenu) {
        if (isLocal) {
          window.location.href = process.env.VUE_APP_ADMIN_WEB_ENDPOINT + `${item.link}`;
        } else {
          window.location.href = `${item.link}`;
        }
      }
    },
  },
};
</script>

<style scoped>
.gnb_purchase .list_gnb:hover .on .link_gnb + .lnb_purchase {
  display: none;
}
.gnb_purchase .list_gnb:hover .on:hover .link_gnb + .lnb_purchase {
  display: block;
}
.gnb_purchase .list_gnb > li {
  float: left;
  position: relative;
}
/* .gnb_purchase .list_gnb>li:nth-child(4) .list_lnb{padding:0 18px 0 42px}
.gnb_purchase .list_gnb>li:nth-child(5) .list_lnb{padding:0 25px 0 18px} */
.gnb_purchase .list_gnb > li:nth-child(4) ~ li .lnb_purchase {
  right: 0;
}
.gnb_purchase .list_gnb > li:nth-child(4) ~ li .list_lnb {
  text-align: right;
}
.gnb_purchase .link_gnb {
  display: block;
  position: relative;
  padding: 0 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 70px;
  color: #fff;
}
.gnb_purchase .link_gnb:after {
  display: none;
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 0;
  height: 4px;
  background-color: #fae100;
  content: "";
}
.gnb_purchase .list_gnb li:hover .link_gnb,
.gnb_purchase .on .link_gnb {
  font-weight: bold;
  color: #fae100;
}
.gnb_purchase .list_gnb li:hover .link_gnb:after,
.gnb_purchase .on .link_gnb:after {
  display: block;
}
.gnb_purchase .list_gnb li:hover .lnb_purchase,
.gnb_purchase .on .link_gnb + .lnb_purchase {
  display: block;
}
/* lnb */
.lnb_purchase {
  display: none;
  position: absolute;
  width: 765px;
}
.lnb_purchase:after {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 47px;
  border-bottom: 1px solid #e1e1e1;
  background-color: #fffbed;
  text-align: center;
  content: "";
}
.lnb_purchase .list_lnb {
  padding: 0 18px;
  font-size: 0;
  text-align: left;
  vertical-align: top;
}
.lnb_purchase .list_lnb li {
  display: inline-block;
  vertical-align: top;
}
.lnb_purchase .list_lnb li + li {
  margin-left: 56px;
}
.lnb_purchase .list_lnb .on .link_lnb,
.lnb_purchase .list_lnb .link_lnb:hover {
  font-weight: 500;
  text-decoration: underline;
}
.lnb_purchase .link_lnb {
  display: block;
  font-size: 13px;
  line-height: 48px;
  color: #555;
}
</style>
